import { GridColumns } from '@material-ui/data-grid';
import { Config } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import axiosObj from 'axios';

import axios from '../axios';
import { GENDER } from './reporting';

export const FORMULA_TABS = [
  { id: 1, name: 'Assessment' },
  { id: 2, name: 'Drug Class' },
  { id: 3, name: 'Drug Name' },
  { id: 4, name: 'Conditions' },
  { id: 5, name: 'Allergies' }
];

export const MANAGE_CRITERION_COLUMNS: GridColumns = [
  { field: 'title', headerName: 'Name', flex: 1.3 }
];

export const PERSONAL_INFO = [
  { id: 1, label: 'Name', name: 'name' },
  { id: 2, label: 'Age', name: 'age' },
  { id: 3, label: 'Date of Birth', name: 'dateOfBirth' },
  { id: 4, label: 'Gender', name: 'gender' }
];

export const HISTORY = [
  {
    id: 1,
    label: 'Have you had a fall in the past three months?',
    name: 'fallInPastThreeMonths'
  },
  { id: 2, label: 'How many times?', name: 'howManyFalls' },
  {
    id: 3,
    label:
      'Do you often feel dizzy or light-headed when you get up from a chair or your bed?',
    name: 'dizzyGettingUp'
  },
  {
    id: 4,
    label:
      "Have you felt unusually confused at any time in the past three months (i.e. couldn't think straight)?",
    name: 'isConfused'
  },
  {
    id: 5,
    label:
      'Have you been in a hospital or nursing home or visited the ER in the past 3 months?',
    name: 'isHospitalized'
  }
];

export const STATUS = [
  {
    id: 1,
    label: 'How much pain have you experienced recently?',
    name: 'pain'
  },
  {
    id: 2,
    label:
      'On average, how many days a week do you drink alcoholic beverages (beer, wine, liquor)?',
    name: 'drinkDays'
  },
  {
    id: 3,
    label: 'On a typical day when you drink, how many drinks do you have?',
    name: 'drinksPerDay'
  }
];

export const BLOOD_PRESSURE = [
  { id: 1, label: 'Blood Pressure - Lying Down', name: 'bpLyingDown' },
  { id: 2, label: 'Blood Pressure - Sitting', name: 'bpSitting' },
  { id: 3, label: 'Blood Pressure - Standing', name: 'bpStanding' }
];

export const PAIN_OPTIONS = [
  { id: 1, name: 'NONE', label: 'None' },
  { id: 2, name: 'MILD', label: 'Mild 1-2' },
  { id: 3, name: 'MODERATE', label: 'Moderate 3-6' },
  { id: 4, name: 'SEVERE', label: 'Severe 7-10' }
];

enum GpiType {
  Group = 2,
  Class = 4,
  SubClass = 6,
  GenericName = 10,
  GenericProduct = 14
}

const GPI_API = {
  [GpiType.Group]: `medispan/medispan/druggroups`,
  [GpiType.Class]: `medispan/medispan/drugclassifications`,
  [GpiType.SubClass]: `medispan/medispan/drugsubclassifications`,
  [GpiType.GenericName]: `medispan/medispan/genericnames`,
  [GpiType.GenericProduct]: `medispan/medispan/genericproducts`
};

let cancelToken;

let InitialConfig = MaterialConfig;

InitialConfig.settings.canShortMongoQuery = false; // PICF-20: Fixing the bug for `==!` operator in Managed Criterion

export const FORMULA_CONFIG: Config = {
  ...InitialConfig,
  fields: {
    age: {
      label: 'Age',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      operators: [
        'equal',
        'greater',
        'less',
        'greater_or_equal',
        'less_or_equal'
      ]
    },
    dateOfBirth: {
      label: 'Date of Birth',
      type: 'date',
      operators: [
        'equal',
        'greater',
        'less',
        'greater_or_equal',
        'less_or_equal'
      ],
      valueSources: ['value']
    },
    gender: {
      label: 'Gender',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: GENDER.map(({ name, value }) => ({
          title: name,
          value
        }))
      },
      operators: ['select_equals', 'select_not_equals']
    },
    fallInPastThreeMonths: {
      label: 'Have you had a fall in the past three months?',
      type: 'boolean',
      operators: ['equal'],
      valueSources: ['value']
    },
    howManyFalls: {
      label: 'How many times?',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    dizzyGettingUp: {
      label:
        'Do you often feel dizzy or light-headed when you get up from a chair or your bed?',
      type: 'boolean',
      operators: ['equal'],
      valueSources: ['value']
    },
    isConfused: {
      label:
        "Have you felt unusually confused at any time in the past three months (i.e. couldn't think straight)?",
      type: 'boolean',
      operators: ['equal'],
      valueSources: ['value']
    },
    isHospitalized: {
      label:
        'Have you been in a hospital or nursing home or visited the ER in the past 3 months?',
      type: 'boolean',
      operators: ['equal'],
      valueSources: ['value']
    },
    pain: {
      label: 'How much pain have you experienced recently?',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: PAIN_OPTIONS.map(({ name, label }) => ({
          title: label,
          value: name
        }))
      },
      operators: ['select_equals']
    },
    drinkDays: {
      label:
        'On average, how many days a week do you drink alcoholic beverages (beer, wine, liquor)?',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [0, 1, 2, 3, 4, 5, 6, 7].map(value => ({
          title: value?.toString(),
          value
        }))
      },
      operators: ['select_equals']
    },
    drinksPerDay: {
      label: 'On a typical day when you drink, how many drinks do you have?',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpLyingDownMin: {
      label: 'Blood Pressure - Lying Down - Min',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpLyingDownMax: {
      label: 'Blood Pressure - Lying Down - Max',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpLyingDownPulse: {
      label: 'Blood Pressure - Lying Down - Pulse',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpLyingDownDateTime: {
      label: 'Blood Pressure - Lying Down - Date/Time Taken',
      type: 'datetime',
      operators: ['equal'],
      valueSources: ['value']
    },
    bpSittingMin: {
      label: 'Blood Pressure - Sitting - Min',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpSittingMax: {
      label: 'Blood Pressure - Sitting - Max',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpSittingPulse: {
      label: 'Blood Pressure - Sitting - Pulse',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpSittingDateTime: {
      label: 'Blood Pressure - Sitting - Date/Time Taken',
      type: 'datetime',
      operators: ['equal'],
      valueSources: ['value']
    },
    bpStandingMin: {
      label: 'Blood Pressure - Standing - Min',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpStandingMax: {
      label: 'Blood Pressure - Standing - Max',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpStandingPulse: {
      label: 'Blood Pressure - Standing - Pulse',
      type: 'number',
      fieldSettings: {
        min: 0
      },
      valueSources: ['value'],
      preferWidgets: ['number'],
      excludeOperators: ['is_empty', 'is_not_empty']
    },
    bpStandingDateTime: {
      label: 'Blood Pressure - Standing - Date/Time Taken',
      type: 'datetime',
      operators: ['equal'],
      valueSources: ['value']
    },
    allConditions: {
      label: 'Conditions',
      type: 'select',
      valueSources: ['value'],
      operators: ['select_equals', 'select_not_equals'],
      fieldSettings: {
        asyncFetch: async (search, offset) => {
          let conditions: any = [];
          let count = 0;

          if (search && search.length > 1)
            await axios
              .post('medispan/medispan/conditions', {
                count: 20,
                startIndex: offset,
                criteria: [
                  {
                    field: 'name',
                    operator: 'contains',
                    value: search
                  }
                ]
              })
              .then(res => {
                count = res.data.data.results.length;
                conditions = res?.data?.data?.results
                  ?.slice(0, 10)
                  ?.map(result => ({
                    title: result?.name,
                    value: result?.name
                  }));
              });

          return { values: conditions, hasMore: count > 10 };
        },
        useAsyncSearch: true,
        useLoadMore: true
      }
    },
    allAllergies: {
      label: 'Allergies',
      type: 'select',
      valueSources: ['value'],
      operators: ['select_equals', 'select_not_equals'],
      fieldSettings: {
        asyncFetch: async (search, offset) => {
          let allergies: any = [];
          let count = 0;

          if (search && search.length > 1)
            await axios
              .post('medispan/medispan/commonallergens', {
                count: 20,
                startIndex: offset,
                criteria: [
                  {
                    field: 'name',
                    operator: 'contains',
                    value: search
                  }
                ]
              })
              .then(res => {
                count = res.data.data.results.length;
                allergies = res?.data?.data?.results
                  ?.slice(0, 10)
                  ?.map(result => ({
                    title: result?.name,
                    value: result?.name
                  }));
              });

          return { values: allergies, hasMore: count > 10 };
        },
        useAsyncSearch: true,
        useLoadMore: true
      }
    },
    drugClass: {
      label: 'Drug Class',
      type: 'select',
      valueSources: ['value'],
      operators: ['select_equals', 'select_not_equals'],
      fieldSettings: {
        asyncFetch: async (search, offset) => {
          let drugClassifications: any = [];
          let count = 0;

          if (search && search.length > 1)
            await axios
              .post('medispan/medispan/drugclassifications', {
                count: 20,
                startIndex: offset,
                criteria: [
                  {
                    field: 'name',
                    operator: 'contains',
                    value: search
                  }
                ]
              })
              .then(res => {
                count = res.data.data.results.length;
                drugClassifications = res?.data?.data?.results
                  ?.slice(0, 10)
                  ?.map(result => ({
                    title: result?.name,
                    value: result?.name
                  }));
              });

          return {
            values: drugClassifications,
            hasMore: count > 10
          };
        },
        useAsyncSearch: true,
        useLoadMore: true
      }
    },
    drugName: {
      label: 'Drug Name',
      type: 'multiselect',
      valueSources: ['value'],
      operators: ['multiselect_equals', 'multiselect_not_equals'],
      fieldSettings: {
        showSearch: true,
        asyncFetch: async search => {
          let drugNames: any = [];

          if (search && search.length > 1)
            await axios


              .post('medispan/medispan/dispensabledrugs', {
                criteria: [
                  {
                    field: 'name',
                    operator: 'startsWith',
                    value: search
                  }
                ],
                fields: ['name', 'id', 'mediSpanId']
              })

              .then(res => {
                drugNames = res?.data?.data?.results
                  ?.slice(0, 10)
                  ?.map(result => ({
                    title: result?.name,
                    value: result?.name
                  }));
                localStorage.setItem(
                  'drugNames',
                  JSON.stringify([
                    ...JSON.parse(localStorage.getItem('drugNames') || ''),
                    ...res?.data?.data?.results
                  ])
                );
              });

          return { values: drugNames, hasMore: false };
        },
        useAsyncSearch: true,
        useLoadMore: false
      }
    },
    gpi: {
      label: 'GPI',
      type: 'select',
      valueSources: ['value'],
      operators: ['select_equals', 'select_not_equals'],
      fieldSettings: {
        showSearch: true,
        asyncFetch: async (search, offset) => {
          let count = 0;
          let results: any = [];
          const type = search?.length;

          if (type && type in GpiType) {
            // The first input of the user/To avoid waiting when clicking on "Load more"
            if (offset === 0) {
              const _ = await new Promise<any>(res => {
                setTimeout(() => res('Done'), 3000);
              });
            }

            // Check if there are any previous pending requests
            if (typeof cancelToken !== typeof undefined) {
              cancelToken.cancel('Operation canceled due to new request.');
            }

            // Save the cancel token for the current request
            cancelToken = axiosObj.CancelToken.source();

            const _ = await axios
              .post(
                GPI_API[type],
                {
                  criteria: [
                    {
                      field:
                        type === GpiType.GenericProduct ? `gpi` : `tc${type}`,
                      operator: 'contains',
                      value: search?.match(/.{1,2}/g)?.join('-')
                    }
                  ],
                  startIndex: offset,
                  count: 20
                },
                { cancelToken: cancelToken.token } // Pass the cancel token to the current request to cancel previous requests
              )
              .then(res => {
                const items = res?.data?.data?.results;
                count = items?.length || 0;
                results =
                  items?.slice(0, 10)?.map(item => ({
                    title: `${search} - ${item?.name}`,
                    value: `${search} - ${item?.name}`
                  })) || [];
              })
              .catch(() => {
                count = 0;
                results = [];
              });
          }

          return { values: results, hasMore: count > 10 };
        },
        useAsyncSearch: true,
        useLoadMore: true
      }
    }
  }
};
