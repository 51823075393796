import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  List,
  Paper,
  Button,
  Popper,
  MenuList,
  MenuItem,
  ListItem,
  ClickAwayListener
} from '@material-ui/core';

import { styles } from './styles';
import { PATH_NAME } from '../../../../routes/pathNames';
import { ArrowDownAdminIcon } from '../../../../assets/icons';
import PrivateComponent from '../../../../sharedComponents/PrivateComponentWrapper';
import {
  HEADER_LIST,
  ALL_PERMISSIONS,
  HEADER_ADMIN_LIST
} from '../../../../utils/constants';

const HeaderNav = () => {
  const classes = styles();
  const location = useLocation();
  const [openAdmin, setOpenAdmin] = useState(false);
  const anchorRefAdmin = useRef<HTMLButtonElement>(null);

  let adminMenuTimeout;

  const handleOpenAdminMenu = () => {
    setOpenAdmin(true);
    clearTimeout(adminMenuTimeout);
  };

  const handleClickAwayAdmin = () => {
    adminMenuTimeout = setTimeout(() => {
      setOpenAdmin(false);
    }, 100);
  };

  const handleClickAdminButton = () => {
    setOpenAdmin(!openAdmin);
  };

  return (
    <List component="nav" className={classes.headerNav}>
      {HEADER_LIST.map(({ id, name, url, permission }) =>
        permission ? (
          <PrivateComponent key={id} permission={permission}>
            <ListItem
              key={id}
              classes={{
                root: classes.menuItem,
                selected: classes.selectedMenuItem
              }}
              selected={location.pathname === url}
            >
              <Link to={url} className={classes.menuLink}>
                {name}
              </Link>
            </ListItem>
          </PrivateComponent>
        ) : (
          <ListItem
            key={id}
            classes={{
              root: classes.menuItem,
              selected: classes.selectedMenuItem
            }}
            selected={location.pathname === url}
          >
            <Link to={url} className={classes.menuLink}>
              {name}
            </Link>
          </ListItem>
        )
      )}
      <PrivateComponent permission={ALL_PERMISSIONS.VIEW_ADMIN_TAB}>
        <ListItem classes={{ root: classes.menuItem }}>
          <div
            onMouseEnter={handleOpenAdminMenu}
            onMouseLeave={handleClickAwayAdmin}
          >
            <Button
              ref={anchorRefAdmin}
              onClick={handleClickAdminButton}
              onMouseEnter={handleOpenAdminMenu}
              classes={{
                root: classNames(classes.menuItem, classes.adminButton, {
                  [classes.selectedMenuItem]:
                    location.pathname === PATH_NAME.SITES ||
                    location.pathname === PATH_NAME.CONFIGURE ||
                    location.pathname === PATH_NAME.PHARMACIES ||
                    location.pathname === PATH_NAME.MANAGE_CRITERION
                })
              }}
            >
              Admin
              <ArrowDownAdminIcon className={classes.adminArrowButton} />
            </Button>
            <Popper
              open={openAdmin}
              anchorEl={anchorRefAdmin.current}
              className={classes.adminPopper}
            >
              <Paper
                classes={{ root: classes.paper }}
                onFocus={handleOpenAdminMenu}
              >
                <ClickAwayListener onClickAway={handleClickAwayAdmin}>
                  <MenuList
                    autoFocusItem={openAdmin}
                    classes={{ root: classes.adminMenuList }}
                  >
                    {HEADER_ADMIN_LIST.map(({ id, url, name, permission }) =>
                      permission ? (
                        <PrivateComponent key={id} permission={permission}>
                          <Link
                            to={url}
                            className={classes.menuLink}
                            onClick={handleClickAdminButton}
                          >
                            <MenuItem classes={{ root: classes.adminMenuItem }}>
                              {name}
                            </MenuItem>
                          </Link>
                        </PrivateComponent>
                      ) : (
                        <Link
                          to={url}
                          key={id}
                          className={classes.menuLink}
                          onClick={handleClickAdminButton}
                        >
                          <MenuItem classes={{ root: classes.adminMenuItem }}>
                            {name}
                          </MenuItem>
                        </Link>
                      )
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
        </ListItem>
      </PrivateComponent>
    </List>
  );
};

export default HeaderNav;
