import { gql } from '@apollo/client';

export const GET_USER_QUERY = gql`
  query GetUserQuery($data: UserQueryInput!) {
    getUserQuery(data: $data) {
      data {
        id
        firstName
        lastName
        email
        active
        siteTitle
        role
      }
      count
    }
  }
`;
