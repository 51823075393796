import { GridColumns, GridColDef } from '@material-ui/data-grid';

import { roleModel } from '../../models';

export const ACTIVE_STATUS = [
  { id: 'all', name: 'All Accounts' },
  { id: 'true', name: 'Active Accounts' },
  { id: 'false', name: 'Inactive Accounts' }
];

const peopleTypes = [
  { id: 'GLOBAL_ADMIN', name: 'Global Admins' },
  { id: 'SITE_ADMIN', name: 'Site Admins' },
  { id: 'ANALYST', name: 'Analyst' },
  { id: 'PHARMACIST_SUPERVISOR', name: 'Pharmacist Supervisor' },
  { id: 'PHARMACIST', name: 'Pharmacist' },
  { id: 'STAFF_SUPERVISOR', name: 'Staff Supervisor' },
  { id: 'STAFF', name: 'Staff' },
  { id: 'PATIENT', name: 'Patients' }
];

export const PEOPLE_TYPE_FILTER = (role: roleModel) => {
  switch (role) {
    case 'GLOBAL_ADMIN':
    case 'ANALYST':
      return localStorage.getItem('defaultSiteId') === 'all_sites'
        ? peopleTypes
        : peopleTypes.filter(type => type.id !== 'GLOBAL_ADMIN');
    case 'SITE_ADMIN':
      return peopleTypes.filter(type => type.id !== 'GLOBAL_ADMIN');
    case 'PHARMACIST_SUPERVISOR':
      return peopleTypes
        .filter(type => type.id !== 'GLOBAL_ADMIN')
        .filter(type => type.id !== 'SITE_ADMIN')
        .filter(type => type.id !== 'PHARMACIST_SUPERVISOR')
        .filter(type => type.id !== 'STAFF_SUPERVISOR');
    case 'PHARMACIST':
      return peopleTypes
        .filter(type => type.id !== 'GLOBAL_ADMIN')
        .filter(type => type.id !== 'SITE_ADMIN')
        .filter(type => type.id !== 'ANALYST')
        .filter(type => type.id !== 'PHARMACIST_SUPERVISOR');
    case 'STAFF':
      return peopleTypes.filter(
        type => type.id === 'PATIENT' || type.id === 'PHARMACIST'
      );
    case 'STAFF_SUPERVISOR':
      return peopleTypes.filter(
        type =>
          type.id === 'STAFF' ||
          type.id === 'PATIENT' ||
          type.id === 'PHARMACIST'
      );
    default:
      return [];
  }
};

export const USER_SITES_COLUMNS: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    hide: true
  },
  { field: 'userName', headerName: 'Name', sortable: false, flex: 2 },
  { field: 'email', headerName: 'Email', sortable: false, flex: 1 },
  { field: 'siteName', headerName: 'Site', sortable: false, flex: 1 },
  {
    field: 'roleName',
    headerName: 'Role',
    sortable: false,
    flex: 1,
    valueGetter: params => {
      const roleName = peopleTypes?.find(
        type => type?.id === params.value
      )?.name;
      return roleName?.endsWith('s') ? roleName?.slice(0, -1) : roleName;
    }
  }
];

export const PEOPLE_TYPE = (role: roleModel) => {
  switch (role) {
    case 'ANALYST':
      return [];
    case 'GLOBAL_ADMIN':
      return peopleTypes;
    case 'SITE_ADMIN':
      return peopleTypes.filter(type => type.id !== 'GLOBAL_ADMIN');
    case 'PHARMACIST_SUPERVISOR':
      return peopleTypes.filter(
        type =>
          type.id === 'PATIENT' ||
          type.id === 'STAFF' ||
          type.id === 'PHARMACIST'
      );
    case 'PHARMACIST':
    case 'STAFF':
      return peopleTypes.filter(type => type.id === 'PATIENT');
    case 'STAFF_SUPERVISOR':
      return peopleTypes.filter(
        type =>
          type.id === 'STAFF' ||
          type.id === 'PATIENT' ||
          type.id === 'PHARMACIST'
      );
    default:
      return [];
  }
};

export function IS_USER_EDITABLE(userType, userRole: roleModel) {
  switch (userRole) {
    case 'GLOBAL_ADMIN':
      return userType === 'GLOBAL_ADMIN'
        ? peopleTypes.map(type => type?.id)?.includes(userType)
        : peopleTypes.map(type => type?.id)?.includes(userType) &&
            localStorage.getItem('defaultSiteId') !== 'all_sites';
    case 'SITE_ADMIN':
      return peopleTypes
        .filter(type => type.id !== 'GLOBAL_ADMIN')
        .map(type => type?.id)
        ?.includes(userType);
    case 'PHARMACIST_SUPERVISOR':
      return peopleTypes
        .filter(type => type.id === 'PHARMACIST' || type.id === 'PATIENT')
        .map(type => type?.id)
        ?.includes(userType);
    case 'PHARMACIST':
    case 'STAFF':
      return peopleTypes
        .filter(type => type.id === 'PATIENT')
        .map(type => type?.id)
        ?.includes(userType);
    case 'STAFF_SUPERVISOR':
      return peopleTypes
        .filter(type => type.id === 'STAFF' || type.id === 'PATIENT')
        .map(type => type?.id)
        ?.includes(userType);
    default:
      return false;
  }
}

function getFullName(params) {
  return `${params.getValue(params.id, 'firstName') || ''} ${
    params.getValue(params.id, 'lastName') || ''
  }`;
}

export const PEOPLE_COLUMNS: GridColumns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.5,
    valueGetter: getFullName,
    sortComparator: (v1, v2) => {
      const lastName1 = (v1 as string)?.split(' ')[1];
      const lastName2 = (v2 as string)?.split(' ')[1];
      return lastName1
        ?.toString()
        .localeCompare(lastName2?.toString()) as number;
    }
  },
  { field: 'id', headerName: 'Identifier', sortable: false, flex: 1.1 },

  { field: 'email', headerName: 'Email', flex: 0.8 },
  { field: 'optionalPatientSiteId', headerName: 'Patient Site ID', flex: 0.9 },
  { field: 'siteTitle', headerName: 'Site Name', flex: 0.8 },
  { field: 'active', headerName: 'Status', flex: 0.4, cellClassName: 'status' },
  { field: 'notActivatedYet', headerName: ' ', sortable: false, flex: 0.4 }
];
