import {
  Paragraph,
  HeadingLevel,
  Table,
  TableRow,
  TableCell,
  TableLayoutType
} from 'docx';
import { GENDER, PAIN_OPTIONS } from '../../constants';
import {
  arraysCombiner,
  calculateAge,
  assessmentReportPdf
} from '../../helpers';

const Title = (title: string) =>
  new Paragraph({
    text: title,
    heading: HeadingLevel.HEADING_1
  });

const noAlertDisclaimerText =
  'The information has been entered into HomeMeds, a medication assessment tool, and no medication alerts have been generated. Because no alerts were generated, this report has not been reviewed by a pharmacist. Appropriate medical judgment must be maintained when using this information.';

export const prepareComprehensiveDocumentBody = report => ({
  children: [
    Title('Member Details'),
    new Paragraph(''),
    new Table({
      layout: TableLayoutType.FIXED,
      columnWidths: [
        ...(report?.patient?.optionalPatientSiteId
          ? [1485, 1485, 1485, 1485, 1485, 1485, 1000]
          : [1666, 1666, 1666, 1666, 1666, 1666])
      ],
      rows: [
        // headers
        new TableRow({
          children: [
            ...(report?.patient?.optionalPatientSiteId
              ? [
                  new TableCell({
                    children: [new Paragraph('ID Number')]
                  })
                ]
              : []),
            new TableCell({
              children: [new Paragraph('Patient Name')]
            }),
            new TableCell({
              children: [new Paragraph('Age')]
            }),
            new TableCell({
              children: [new Paragraph('DOB')]
            }),
            new TableCell({
              children: [new Paragraph('Phone')]
            }),
            new TableCell({
              children: [new Paragraph('Address')]
            }),
            new TableCell({
              children: [new Paragraph('Gender')]
            })
          ]
        }),
        // rows
        new TableRow({
          children: [
            ...(report?.patient?.optionalPatientSiteId
              ? [
                  new TableCell({
                    children: [
                      new Paragraph(
                        report?.patient?.optionalPatientSiteId || 'None'
                      )
                    ]
                  })
                ]
              : []),
            new TableCell({
              children: [
                new Paragraph(
                  `${report?.patient?.firstName || ''} ${
                    report?.patient?.lastName || ''
                  }` || ''
                )
              ]
            }),
            new TableCell({
              children: [
                new Paragraph(calculateAge(report?.patient?.date) || '')
              ]
            }),
            new TableCell({
              children: [
                new Paragraph(
                  `${report?.patient?.date?.split('-')[1]}/${
                    report?.patient?.date?.split('-')[2]
                  }/${report?.patient?.date?.split('-')[0]}`
                )
              ]
            }),
            new TableCell({
              children: [new Paragraph(report?.patient?.primaryPhone || '')]
            }),
            new TableCell({
              children: [
                new Paragraph(
                  report?.patient?.addresses &&
                  report?.patient?.addresses.length > 0
                    ? `${
                        report?.patient?.addresses?.find(
                          address =>
                            address.id === report?.patient?.primaryAddressId
                        )?.address1
                      } ${
                        report?.patient?.addresses?.find(
                          address =>
                            address.id === report?.patient?.primaryAddressId
                        )?.city
                      } ${
                        report?.patient?.addresses?.find(
                          address =>
                            address.id === report?.patient?.primaryAddressId
                        )?.state
                      } ${
                        report?.patient?.addresses?.find(
                          address =>
                            address.id === report?.patient?.primaryAddressId
                        )?.zip
                      }`
                    : ''
                )
              ]
            }),
            new TableCell({
              children: [
                new Paragraph(
                  GENDER.find(
                    gender => gender?.value === report?.patient?.gender
                  )?.name || ''
                )
              ]
            })
          ]
        })
      ]
    }),
    new Paragraph(''),
    Title('Self-Reported Incidents, Health-Related Habits, Signs and Symptoms'),
    new Paragraph(''),
    new Table({
      layout: TableLayoutType.FIXED,
      columnWidths: [5000, 5000],
      rows: [
        // headers
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph('Incidents/Habits/Symptoms')]
            }),
            new TableCell({
              children: [new Paragraph('Details')]
            })
          ]
        }),
        // rows
        ...(report?.fallInPast3Months
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient reports having a fall ${report?.numberOfFalls} time(s) within the last 3 months.`
                      )
                    ]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Most serious fall occurred: ${report?.fallDescription}, Injuries reported include: ${report?.fallInjuries}`
                      )
                    ]
                  })
                ]
              })
            ]
          : []),
        ...(report?.amountOfPainExperienced
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph('Amount of Pain experienced')]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        PAIN_OPTIONS?.find(
                          painItem =>
                            painItem?.name === report?.amountOfPainExperienced
                        )?.label ?? ''
                      )
                    ]
                  })
                ]
              })
            ]
          : []),

        ...(report?.visitedHospital
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has been to ${
                          report?.visitedPlaceType === 'nursingHome'
                            ? 'nursing home'
                            : report?.visitedPlaceType
                        }`
                      )
                    ]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(`Yes, in the last ${report?.visitDate}`)
                    ]
                  })
                ]
              })
            ]
          : []),
        ...(report?.dizzy
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has felt often dizzy or lightheaded`
                      )
                    ]
                  }),
                  new TableCell({
                    children: [new Paragraph(`${report?.dizzinessDetails}`)]
                  })
                ]
              })
            ]
          : []),
        ...(report?.confusion
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has felt unusually confused in the past three months`
                      )
                    ]
                  }),
                  new TableCell({
                    children: [new Paragraph(`Yes`)]
                  })
                ]
              })
            ]
          : []),
        ...(report?.daysOfAlcoholUsagePerWeek
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient drinks alcoholic beverages ${
                          report?.daysOfAlcoholUsagePerWeek === 1
                            ? `${report?.daysOfAlcoholUsagePerWeek} day`
                            : `${report?.daysOfAlcoholUsagePerWeek} days`
                        } a week, on average`
                      )
                    ]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has ${
                          report?.numberOfDrinksPerDay === 1
                            ? `${report?.numberOfDrinksPerDay} drink`
                            : `${report?.numberOfDrinksPerDay} drinks`
                        } on a typical day`
                      )
                    ]
                  })
                ]
              })
            ]
          : []),
        ...(report?.bpLyingNum && report?.bpLyingDenom
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has a blood pressure of ${report?.bpLyingNum}/${report?.bpLyingDenom} when lying down`
                      )
                    ]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has a heart rate of ${
                          report?.lyingPulse || ''
                        } taken on ${
                          report?.lyingPressureCapturedAt
                            ? new Date(
                                report?.lyingPressureCapturedAt
                              ).toLocaleDateString('en-US')
                            : ''
                        }`
                      )
                    ]
                  })
                ]
              })
            ]
          : []),
        ...(report?.bpSittingNum && report?.bpSittingDenom
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has a blood pressure of ${report?.bpSittingNum}/${report?.bpSittingDenom} when sitting`
                      )
                    ]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has a heart rate of ${
                          report?.sittingPulse || ''
                        } taken on ${
                          report?.sittingPressureCapturedAt
                            ? new Date(
                                report?.sittingPressureCapturedAt
                              ).toLocaleDateString('en-US')
                            : ''
                        }`
                      )
                    ]
                  })
                ]
              })
            ]
          : []),
        ...(report?.bpStandingNum && report?.bpStandingDenom
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has a blood pressure of ${report?.bpStandingNum}/${report?.bpStandingDenom} when standing`
                      )
                    ]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        `Patient has a heart rate of ${
                          report?.standingPulse || ''
                        } taken on ${
                          report?.standingPressureCapturedAt
                            ? new Date(
                                report?.standingPressureCapturedAt
                              ).toLocaleDateString('en-US')
                            : ''
                        }`
                      )
                    ]
                  })
                ]
              })
            ]
          : [])
      ]
    }),
    new Paragraph(''),
    Title('Medication List'),
    new Paragraph(''),
    new Table({
      columnWidths: [1430, 1430, 1430, 1430, 1430, 1430, 1430],
      layout: TableLayoutType.FIXED,
      rows: [
        // headers
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph('Drug')]
            }),
            new TableCell({
              children: [new Paragraph('Frequency')]
            }),
            new TableCell({
              children: [new Paragraph('Route')]
            }),
            new TableCell({
              children: [new Paragraph('Directions')]
            }),
            new TableCell({
              children: [new Paragraph('Prescriber')]
            }),
            new TableCell({
              children: [new Paragraph('Pharmacy')]
            }),
            new TableCell({
              children: [new Paragraph('Notes')]
            })
          ]
        }),
        // rows
        ...(report?.drugs
          ? report?.drugs?.map(
              drug =>
                new TableRow({
                  children: [
                    new TableCell({
                      children: [new Paragraph(`${drug?.name || ''} ` || '')]
                    }),
                    new TableCell({
                      children: [
                        new Paragraph(
                          `${drug?.quantity || ''} ${
                            drug?.quantityMeasurement || ''
                          } ${drug?.frequency || ''} ${
                            drug?.asNeeded ? 'as needed' : ''
                          }` || ''
                        )
                      ]
                    }),
                    new TableCell({
                      children: [new Paragraph(`${drug?.route || ''}`)]
                    }),
                    new TableCell({
                      children: [new Paragraph(`${drug?.direction || ''}`)]
                    }),
                    new TableCell({
                      children: [new Paragraph(drug?.providerName || '')]
                    }),
                    new TableCell({
                      children: [new Paragraph(drug?.pharmacyName || '')]
                    }),
                    new TableCell({
                      children: [new Paragraph(drug?.notes || '')]
                    })
                  ]
                })
            )
          : []),
        ...(report?.drugsNote
          ? [
              new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph(report?.drugsNote || '')]
                  }),
                  new TableCell({
                    children: [new Paragraph('')]
                  }),
                  new TableCell({
                    children: [new Paragraph('')]
                  }),
                  new TableCell({
                    children: [new Paragraph('')]
                  }),
                  new TableCell({
                    children: [new Paragraph('')]
                  }),
                  new TableCell({
                    children: [new Paragraph('')]
                  }),
                  new TableCell({
                    children: [new Paragraph('')]
                  })
                ]
              })
            ]
          : [])
      ]
    }),
    new Paragraph(''),
    Title('Self-Reported Health Conditions and Surgeries'),
    new Paragraph(''),
    ...(report.conditions
      ? report?.conditions?.map(condition => new Paragraph(condition || ''))
      : [new Paragraph('No reported conditions')]),
    ...(report?.conditionNote
      ? [new Paragraph(report?.conditionNote || '')]
      : []),
    new Paragraph(''),
    Title('Allergies'),
    new Paragraph(''),
    ...(report.allergies
      ? report?.allergies?.map(allergy => new Paragraph(allergy || ''))
      : [new Paragraph('No reported allergies')]),
    ...(report?.allergyNote ? [new Paragraph(report?.allergyNote || '')] : []),
    new Paragraph(''),
    Title('Potential Medication Related Problems'),
    new Paragraph(''),
    new Table({
      columnWidths: [2000, 2000, 2000, 2000, 2000],
      layout: TableLayoutType.FIXED,
      rows: [
        // headers
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph('Alert')]
            }),
            new TableCell({
              children: [new Paragraph('Status')]
            }),
            new TableCell({
              children: [new Paragraph('Type')]
            }),
            new TableCell({
              children: [new Paragraph('Notes')]
            }),
            new TableCell({
              children: [new Paragraph('Date')]
            })
          ]
        }),
        // rows
        ...report?.alerts?.criterionAlerts?.flatMap(alert => {
          const entry = getLatestAlertUpdate(alert?.history);
          const createdAt = entry?.createdAt || alert?.createdAt;
          return [
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph(alert?.criterion?.title || '')]
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${alert?.status?.charAt(0)}${alert?.status
                        ?.slice(1)
                        ?.toLowerCase()}` || ''
                    )
                  ]
                }),
                new TableCell({
                  children: [new Paragraph(entry?.lastAction || '')]
                }),
                new TableCell({
                  children: [new Paragraph(entry?.notes || '')]
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      createdAt
                        ? new Date(createdAt as string)?.toLocaleDateString()
                        : ''
                    )
                  ]
                })
              ]
            })
          ];
        })
      ]
    }),
    new Paragraph(''),
    Title('Duplicate Therapies Found'),
    new Paragraph(''),
    new Table({
      columnWidths: [2000, 2000, 2000, 2000, 2000],
      layout: TableLayoutType.FIXED,
      rows: [
        // headers
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph('Alert')]
            }),
            new TableCell({
              children: [new Paragraph('Status')]
            }),
            new TableCell({
              children: [new Paragraph('Type')]
            }),
            new TableCell({
              children: [new Paragraph('Notes')]
            }),
            new TableCell({
              children: [new Paragraph('Date')]
            })
          ]
        }),
        // rows
        ...report?.alerts?.medispanAlerts
          ?.filter?.(alert => alert?.type === 'THERAPY')
          ?.flatMap(alert => {
            const entry = getLatestAlertUpdate(alert?.history);
            const createdAt = entry?.createdAt || alert?.createdAt;
            return [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `${alert?.drugs
                          ?.map(drug => drug?.name)
                          ?.join('-')} Alert` || ''
                      )
                    ]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        `${alert?.status?.charAt(0)}${alert?.status
                          ?.slice(1)
                          ?.toLowerCase()}` || ''
                      )
                    ]
                  }),
                  new TableCell({
                    children: [new Paragraph(entry?.lastAction || '')]
                  }),
                  new TableCell({
                    children: [new Paragraph(entry?.notes || '')]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        createdAt
                          ? new Date(createdAt as string)?.toLocaleDateString()
                          : ''
                      )
                    ]
                  })
                ]
              })
            ];
          })
      ]
    }),
    new Paragraph(''),
    Title('Duplicate Ingredients Found'),
    new Paragraph(''),
    new Table({
      columnWidths: [2000, 2000, 2000, 2000, 2000],
      layout: TableLayoutType.FIXED,
      rows: [
        // headers
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph('Alert')]
            }),
            new TableCell({
              children: [new Paragraph('Status')]
            }),
            new TableCell({
              children: [new Paragraph('Type')]
            }),
            new TableCell({
              children: [new Paragraph('Notes')]
            }),
            new TableCell({
              children: [new Paragraph('Date')]
            })
          ]
        }),
        // rows
        ...report?.alerts?.medispanAlerts
          ?.filter?.(alert => alert?.type === 'INGREDIENT')
          ?.flatMap(alert => {
            const entry = getLatestAlertUpdate(alert?.history);
            const createdAt = entry?.createdAt || alert?.createdAt;
            return [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph(
                        `${alert?.drugs
                          ?.map(drug => drug?.name)
                          ?.join('-')} Alert` || ''
                      )
                    ]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        `${alert?.status?.charAt(0)}${alert?.status
                          ?.slice(1)
                          ?.toLowerCase()}` || ''
                      )
                    ]
                  }),
                  new TableCell({
                    children: [new Paragraph(entry?.lastAction || '')]
                  }),
                  new TableCell({
                    children: [new Paragraph(entry?.notes || '')]
                  }),
                  new TableCell({
                    children: [
                      new Paragraph(
                        createdAt
                          ? new Date(createdAt as string)?.toLocaleDateString()
                          : ''
                      )
                    ]
                  })
                ]
              })
            ];
          })
      ]
    }),
    new Paragraph(''),
    Title('Health Providers and Servicing Pharmacies'),
    new Paragraph(''),
    new Table({
      layout: TableLayoutType.FIXED,
      columnWidths: [5000, 5000],
      rows: [
        // headers
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph('Provider')]
            }),
            new TableCell({
              children: [new Paragraph('Pharmacy')]
            })
          ]
        }),
        // rows
        ...arraysCombiner(
          report?.patient?.pharmacies?.map(p => ({ pharmacy: p })),
          report?.patient?.providers?.map(p => ({ provider: p }))
        )?.map(
          prescriber =>
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        prescriber?.provider?.name
                          ? prescriber?.provider?.name || ''
                          : `${prescriber?.provider?.firstName || ''} ${
                              prescriber?.provider?.lastName || ''
                            }`
                      }
        ${prescriber?.provider?.address || ''}${
                        prescriber?.provider?.address &&
                        (prescriber?.provider?.city ||
                          prescriber?.provider?.state ||
                          prescriber?.provider?.zip)
                          ? ', '
                          : ''
                      }${
                        prescriber?.provider?.city
                          ? prescriber?.provider?.city.replace(', ', '')
                          : ''
                      }${
                        prescriber?.provider?.city &&
                        (prescriber?.provider?.state ||
                          prescriber?.provider?.zip)
                          ? ', '
                          : ''
                      }${prescriber?.provider?.state || ''}${
                        prescriber?.provider?.state && prescriber?.provider?.zip
                          ? ', '
                          : ''
                      }${prescriber?.provider?.zip || ''}
        ${prescriber?.provider?.phone || ''}
        ${prescriber?.provider?.fax ? 'Fax: ' : ''}${
                        prescriber?.provider?.fax || ''
                      }`
                    )
                  ]
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `${
                        prescriber?.pharmacy?.name
                          ? prescriber?.pharmacy?.name || ''
                          : `${prescriber?.pharmacy?.firstName || ''} ${
                              prescriber?.pharmacy?.lastName || ''
                            }`
                      }
        ${prescriber?.pharmacy?.address || ''}${
                        prescriber?.pharmacy?.address &&
                        (prescriber?.pharmacy?.city ||
                          prescriber?.pharmacy?.state ||
                          prescriber?.pharmacy?.zip)
                          ? ', '
                          : ''
                      }${
                        prescriber?.pharmacy?.city
                          ? prescriber?.pharmacy?.city.replace(', ', '')
                          : ''
                      }${
                        prescriber?.pharmacy?.city &&
                        (prescriber?.pharmacy?.state ||
                          prescriber?.pharmacy?.zip)
                          ? ', '
                          : ''
                      }${prescriber?.pharmacy?.state || ''}${
                        prescriber?.pharmacy?.state && prescriber?.pharmacy?.zip
                          ? ', '
                          : ''
                      }${prescriber?.pharmacy?.zip || ''}
        ${prescriber?.pharmacy?.phone || ''}
        ${prescriber?.pharmacy?.fax ? 'Fax: ' : ''}${
                        prescriber?.pharmacy?.fax || ''
                      }`
                    )
                  ]
                })
              ]
            })
        )
      ]
    }),
    new Paragraph(''),
    Title('Disclaimer'),
    new Paragraph(''),
    new Table({
      layout: TableLayoutType.FIXED,
      columnWidths: [10000],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph(
                  `${
                    !report?.alerts?.criterionAlerts.length &&
                    !report?.alerts?.medispanAlerts.length
                      ? noAlertDisclaimerText
                      : report?.disclaimer || ''
                  }`
                ),
                new Paragraph('')
              ]
            })
          ]
        })
      ]
    })
  ]
});

const {
  getTableTitle,
  getMemberDetails,
  getIncidents,
  getMedications,
  getConditions,
  getAllergies,
  getProviders,
  getPotentialProblems,
  getTherapies,
  getIngredients,
  getLatestAlertUpdate,
  getDisclaimer
} = assessmentReportPdf;

export const prepareComprehensivePdfDocDefinition = report => [
  getTableTitle('Member Details'),
  getMemberDetails(report),
  getTableTitle(
    'Self-Reported Incidents, Health-Related Habits, Signs and Symptoms'
  ),
  getIncidents(report),
  getTableTitle('Medication List'),
  getMedications(report),
  getTableTitle('Self-Reported Health Conditions and Surgeries'),
  getConditions(report),
  getTableTitle('Allergies'),
  getAllergies(report),
  getTableTitle('Potential Medication Related Problems'),
  getPotentialProblems(report),
  getTableTitle('Duplicate Therapies Found'),
  getTherapies(report),
  getTableTitle('Duplicate Ingredients Found'),
  getIngredients(report),
  getTableTitle('Health Providers and Servicing Pharmacies'),
  getProviders(report),
  getTableTitle('Disclaimer'),
  getDisclaimer(report)
];
