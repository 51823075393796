import { GridColDef, GridValueGetterParams } from '@material-ui/data-grid';

export const MEMBER_DETAILS_COLUMNS: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID Number',
    sortable: false,
    flex: 1.8,
    hide: true
  },
  { field: 'name', headerName: 'Patient Name', sortable: false, flex: 1 },
  { field: 'age', headerName: 'Age', sortable: false, flex: 0.7 },
  { field: 'dateOfBirth', headerName: 'DOB', sortable: false, flex: 0.6 },
  { field: 'phoneNumber', headerName: 'Phone', sortable: false, flex: 0.7 },
  { field: 'address', headerName: 'Address', sortable: false, flex: 2 },
  { field: 'gender', headerName: 'Gender', sortable: false, flex: 1 }
];

export const REPORTED_INCIDENTS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', sortable: false, hide: true, flex: 1 },
  {
    field: 'incident',
    headerName: 'Incidents/Habits/Symptoms',
    sortable: false,
    flex: 1
  },
  { field: 'details', headerName: 'Details', sortable: false, flex: 2 }
];

export const MEMBER_DETAILS_ROWS = [
  {
    id: '12345678',
    name: 'Jane Doe',
    age: '90',
    dateOfBirth: '03/03/1930',
    phoneNumber: 'XXX-XXX-XXXX',
    address: '1234 Washington Blvd',
    gender: 'Male'
  }
];

export const MEDICATION_LIST_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true, sortable: false, flex: 1 },
  { field: 'drugName', headerName: 'Drug', sortable: false, flex: 1.7 },
  { field: 'frequency', headerName: 'Frequency', sortable: false, flex: 1.2 },
  { field: 'route', headerName: 'Route', sortable: false, flex: 1.2 },
  { field: 'direction', headerName: 'Directions', sortable: false, flex: 1.2 },
  {
    field: 'providerName',
    headerName: 'Prescriber',
    sortable: false,
    flex: 1.3
  },
  { field: 'pharmacyName', headerName: 'Pharmacy', sortable: false, flex: 1.3 },
  { field: 'notes', headerName: 'Notes', sortable: false, flex: 1.5 }
];

export const MEDICATION_LIST_ROWS = [
  {
    id: '1',
    drugName: 'Tylenol 325 mg oral capsule',
    frequency: '',
    providerName: '',
    pharmacyName: '',
    notes: ''
  },
  {
    id: '2',
    drugName: 'ravastatin 40 mg oral tablet',
    frequency: '1 Tablet(s) twice a day',
    providerName: '',
    pharmacyName: '',
    notes: 'for high cholesterol'
  },
  {
    id: '3',
    drugName: 'amLODIPine 5 mg oral tablet',
    frequency: '1 Tablet(s) daily',
    providerName: '',
    pharmacyName: '',
    notes: 'for high blood pressure'
  },
  {
    id: '4',
    drugName: 'gabapentin 100 mg oral capsule',
    frequency: '2 Capsule(s) daily',
    providerName: '',
    pharmacyName: '',
    notes: 'for nerve pain'
  }
];

export const ALERTS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true, flex: 1 },
  { field: 'alertName', headerName: 'Alert', flex: 2 },
  { field: 'status', headerName: 'Status', flex: 1.2 },
  { field: 'lastAction', headerName: 'Type', flex: 1 },
  { field: 'notes', headerName: 'Notes', flex: 1.3 },
  {
    field: 'createdAt',
    headerName: 'Date',
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) =>
      new Date(params?.value as string)?.toLocaleDateString() || ''
  }
];

export const ALERTS_ROWS = [
  {
    id: '1',
    name: 'Age',
    age: '',
    dateOfBirth: '',
    phoneNumber: '',
    address: '',
    gender: ''
  }
];

export const ALERTS_DUPLICATE_ROWS = [
  {
    id: '1',
    alertName:
      'incretin-based therapies: Bydureon Kit 2 mg subcutaneous injection, extended release Januvia 50 mg oral tablet',
    status: 'Update from Pharmacist',
    type: 'Fax to MD',
    notes: '',
    date: ''
  }
];

export const PROVIDERS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true, flex: 1 },
  { field: 'provider', headerName: 'Provider', sortable: false, flex: 1 },
  { field: 'pharmacy', headerName: 'Pharmacy', sortable: false, flex: 1 }
];

export const PROVIDERS_ROWS = [
  {
    id: '1',
    providerName: `V Athens
3524 Tongass Ave, Ketchikan, AK, 99901-5635
(907) 225-9830
Fax: (907) 225-9840`,
    pharmacyName: ''
  },
  {
    id: '2',
    providerName: `Michael Annavi
610 W Tongass Ave, Ketchikan, AK, 99901-5635
(907) 225-9830
Fax: (907) 225-9840`,
    pharmacyName: ''
  },
  {
    id: '3',
    providerName: `Veronica Accoman
94 Main Street, Ketchikan, AK, 99901-5635
(907) 225-9830
Fax: (907) 225-9840`,
    pharmacyName: ''
  }
];
