import { GENDER, PAIN_OPTIONS } from '../constants';
import { calculateAge, arraysCombiner } from '.';

const styles = {
  headStyles: {
    lineWidth: 0.2,
    lineColor: '#000000',
    textColor: '#000000',
    fillColor: '#ffffff'
  },
  bodyStyles: {
    lineWidth: 0.2,
    lineColor: '#000000',
    textColor: '#000000',
    fillColor: '#ffffff'
  },
  theme: 'grid',
  margin: { top: 30 }
};

const getLatestAlertUpdate = history =>
  history?.length
    ? history?.reduce((prev, current) =>
        new Date(prev?.createdAt) > new Date(current?.createdAt)
          ? prev
          : current
      )
    : {
        createdAt: '',
        updateBy: '',
        lastAction: '',
        notes: '',
        historyId: ''
      };

const getTableTitle = title => ({
  head: [[title]],
  headStyles: {
    fillColor: '#ffffff',
    textColor: '#000000',
    fontSize: 14
  }
});

const getMemberDetails = report => ({
  head: [
    [
      ...(report?.patient?.optionalPatientSiteId ? ['ID Number'] : []),
      'Patient Name',
      'Age',
      'DOB',
      'Phone',
      'Address',
      'Gender'
    ]
  ],
  body: [
    [
      ...(report?.patient?.optionalPatientSiteId
        ? [`${report?.patient?.optionalPatientSiteId || 'None'} `]
        : []),
      `${report?.patient?.firstName || ''} ${
        report?.patient?.lastName || ''
      }` || '',
      calculateAge(report?.patient?.date) || '',
      `${report?.patient?.date?.split('-')[1]}/${
        report?.patient?.date?.split('-')[2]
      }/${report?.patient?.date?.split('-')[0]}`,
      report?.patient?.primaryPhone || '',
      report?.patient?.addresses && report?.patient?.addresses.length > 0
        ? `${
            report?.patient?.addresses?.find(
              address => address.id === report?.patient?.primaryAddressId
            )?.address1
          } ${
            report?.patient?.addresses?.find(
              address => address.id === report?.patient?.primaryAddressId
            )?.city
          } ${
            report?.patient?.addresses?.find(
              address => address.id === report?.patient?.primaryAddressId
            )?.state
          } ${
            report?.patient?.addresses?.find(
              address => address.id === report?.patient?.primaryAddressId
            )?.zip
          }`
        : '',
      GENDER.find(gender => gender?.value === report?.patient?.gender)?.name ||
        ''
    ]
  ],
  ...styles
});

const getIncidents = report => ({
  head: [['Incidents/Habits/Symptoms', 'Details']],
  body: [
    ...(report?.fallInPast3Months
      ? [
          [
            `Patient reports having a fall ${report?.numberOfFalls} time(s) within the last 3 months.`,
            `Most serious fall occurred: ${report?.fallDescription}, Injuries reported include: ${report?.fallInjuries}`
          ]
        ]
      : []),
    ...(report?.amountOfPainExperienced
      ? [
          [
            'Amount of Pain experienced',
            PAIN_OPTIONS?.find(
              painItem => painItem?.name === report?.amountOfPainExperienced
            )?.label ?? ''
          ]
        ]
      : []),
    ...(report?.visitedHospital
      ? [
          [
            `Patient has been to ${
              report?.visitedPlaceType === 'nursingHome'
                ? 'nursing home'
                : report?.visitedPlaceType
            }`,
            `Yes, in the last ${report?.visitDate}`
          ]
        ]
      : []),
    ...(report?.dizzy
      ? [
          [
            `Patient has felt often dizzy or lightheaded`,
            `${report?.dizzinessDetails}`
          ]
        ]
      : []),
    ...(report?.confusion
      ? [
          [
            `Patient has felt unusually confused in the past three months`,
            `Yes`
          ]
        ]
      : []),
    ...(report?.daysOfAlcoholUsagePerWeek
      ? [
          [
            `Patient drinks alcoholic beverages ${
              report?.daysOfAlcoholUsagePerWeek === 1
                ? `${report?.daysOfAlcoholUsagePerWeek} day`
                : `${report?.daysOfAlcoholUsagePerWeek} days`
            } a week, on average`,
            `Patient has ${
              report?.numberOfDrinksPerDay === 1
                ? `${report?.numberOfDrinksPerDay} drink`
                : `${report?.numberOfDrinksPerDay} drinks`
            } on a typical day`
          ]
        ]
      : []),
    ...(report?.bpLyingNum && report?.bpLyingDenom
      ? [
          [
            `Patient has a blood pressure of ${report?.bpLyingNum}/${report?.bpLyingDenom} when lying down`,
            `Patient has a heart rate of ${report?.lyingPulse || ''} taken on ${
              report?.lyingPressureCapturedAt
                ? new Date(report?.lyingPressureCapturedAt).toLocaleDateString(
                    'en-US'
                  )
                : ''
            }`
          ]
        ]
      : []),
    ...(report?.bpSittingNum && report?.bpSittingDenom
      ? [
          [
            `Patient has a blood pressure of ${report?.bpSittingNum}/${report?.bpSittingDenom} when sitting`,
            `Patient has a heart rate of ${
              report?.sittingPulse || ''
            } taken on ${
              report?.sittingPressureCapturedAt
                ? new Date(
                    report?.sittingPressureCapturedAt
                  ).toLocaleDateString('en-US')
                : ''
            }`
          ]
        ]
      : []),
    ...(report?.bpStandingNum && report?.bpStandingDenom
      ? [
          [
            `Patient has a blood pressure of ${report?.bpStandingNum}/${report?.bpStandingDenom} when standing`,
            `Patient has a heart rate of ${
              report?.standingPulse || ''
            } taken on ${
              report?.standingPressureCapturedAt
                ? new Date(
                    report?.standingPressureCapturedAt
                  ).toLocaleDateString('en-US')
                : ''
            }`
          ]
        ]
      : [])
  ],
  ...styles
});

const getMedications = report => ({
  head: [
    [
      'Drug',
      'Frequency',
      'Route',
      'Directions',
      'Prescriber',
      'Pharmacy',
      'Notes'
    ]
  ],
  body: [
    ...(report?.drugs
      ? report?.drugs?.map(drug => [
          `${drug?.name || ''} `,
          `${drug?.quantity || ''} ${drug?.quantityMeasurement || ''} ${
            drug?.frequency || ''
          } ${drug?.asNeeded ? 'as needed' : ''}`,
          `${drug?.route || ''}`,
          `${drug?.direction || ' '}`,
          `${drug?.providerName || ''}`,
          `${drug?.pharmacyName || ''}`,
          `${drug?.notes || ' '}`
        ])
      : []),
    ...(report?.drugsNote ? [[report?.drugsNote || '']] : [])
  ],
  ...styles
});

const getConditions = report => ({
  body: [
    ...(report.conditions
      ? report?.conditions?.map(condition => [`${condition || ''}`])
      : [['No reported conditions']]),
    ...(report?.conditionNote ? [[`${report?.conditionNote || ''}`]] : [])
  ],
  ...styles
});

const getAllergies = report => ({
  head: [],
  body: [
    ...(report.allergies
      ? report?.allergies?.map(allergy => [`${allergy || ''}`])
      : [['No reported allergies']]),
    ...(report?.allergyNote ? [[`${report?.allergyNote || ''}`]] : [])
  ],
  ...styles
});

const getProviders = report => ({
  head: [['Provider', 'Pharmacy']],
  body: [
    ...arraysCombiner(
      report?.patient?.pharmacies?.map(p => ({ pharmacy: p })),
      report?.patient?.providers?.map(p => ({ provider: p }))
    )?.map(prescriber => [
      `${
        prescriber?.provider?.name
          ? prescriber?.provider?.name || ''
          : `${prescriber?.provider?.firstName || ''} ${
              prescriber?.provider?.lastName || ''
            }`
      }
  ${prescriber?.provider?.address || ''}${
        prescriber?.provider?.address &&
        (prescriber?.provider?.city ||
          prescriber?.provider?.state ||
          prescriber?.provider?.zip)
          ? ', '
          : ''
      }${
        prescriber?.provider?.city
          ? prescriber?.provider?.city.replace(', ', '')
          : ''
      }${
        prescriber?.provider?.city &&
        (prescriber?.provider?.state || prescriber?.provider?.zip)
          ? ', '
          : ''
      }${prescriber?.provider?.state || ''}${
        prescriber?.provider?.state && prescriber?.provider?.zip ? ', ' : ''
      }${prescriber?.provider?.zip || ''}
  ${prescriber?.provider?.phone || ''}
  ${prescriber?.provider?.fax ? 'Fax: ' : ''}${
        prescriber?.provider?.fax || ''
      }`,
      `${
        prescriber?.pharmacy?.name
          ? prescriber?.pharmacy?.name || ''
          : `${prescriber?.pharmacy?.firstName || ''} ${
              prescriber?.pharmacy?.lastName || ''
            }`
      }
  ${prescriber?.pharmacy?.address || ''}${
        prescriber?.pharmacy?.address &&
        (prescriber?.pharmacy?.city ||
          prescriber?.pharmacy?.state ||
          prescriber?.pharmacy?.zip)
          ? ', '
          : ''
      }${
        prescriber?.pharmacy?.city
          ? prescriber?.pharmacy?.city.replace(', ', '')
          : ''
      }${
        prescriber?.pharmacy?.city &&
        (prescriber?.pharmacy?.state || prescriber?.pharmacy?.zip)
          ? ', '
          : ''
      }${prescriber?.pharmacy?.state || ''}${
        prescriber?.pharmacy?.state && prescriber?.pharmacy?.zip ? ', ' : ''
      }${prescriber?.pharmacy?.zip || ''}
  ${prescriber?.pharmacy?.phone || ''}
  ${prescriber?.pharmacy?.fax ? 'Fax: ' : ''}${prescriber?.pharmacy?.fax || ''}`
    ])
  ],
  ...styles
});

const getPotentialProblems = report => ({
  head: [['Alert', 'Status', 'Type', 'Notes', 'Date']],
  body: [
    ...report?.alerts?.criterionAlerts?.flatMap(alert => {
      const entry = getLatestAlertUpdate(alert?.history);
      const createdAt = entry?.createdAt || alert?.createdAt;
      return [
        [
          `${alert?.criterion?.title || ''}`,
          `${alert?.status?.charAt(0)}${alert?.status
            ?.slice(1)
            ?.toLowerCase()}` || '',
          `${entry?.lastAction || ''}`,
          `${entry?.notes || ''}`,
          `${
            createdAt ? new Date(createdAt as string)?.toLocaleDateString() : ''
          }`
        ]
      ];
    })
  ],
  ...styles
});

const getTherapies = report => ({
  head: [['Alert', 'Status', 'Type', 'Notes', 'Date']],
  body: [
    ...report?.alerts?.medispanAlerts
      ?.filter?.(alert => alert?.type === 'THERAPY')
      ?.flatMap(alert => {
        const entry = getLatestAlertUpdate(alert?.history);
        const createdAt = entry?.createdAt || alert?.createdAt;
        return [
          [
            `${alert?.drugs?.map(drug => drug?.name)?.join('-')} Alert` || '',
            `${alert?.status?.charAt(0)}${alert?.status
              ?.slice(1)
              ?.toLowerCase()}` || '',
            entry?.lastAction || '',
            entry?.notes || '',
            createdAt ? new Date(createdAt as string)?.toLocaleDateString() : ''
          ]
        ];
      })
  ],
  ...styles
});

const getIngredients = report => ({
  head: [['Alert', 'Status', 'Type', 'Notes', 'Date']],
  body: [
    ...report?.alerts?.medispanAlerts
      ?.filter?.(alert => alert?.type === 'INGREDIENT')
      ?.flatMap(alert => {
        const entry = getLatestAlertUpdate(alert?.history);
        const createdAt = entry?.createdAt || alert?.createdAt;
        return [
          [
            `${alert?.drugs?.map(drug => drug?.name)?.join('-')} Alert` || '',
            `${alert?.status?.charAt(0)}${alert?.status
              ?.slice(1)
              ?.toLowerCase()}` || '',
            entry?.lastAction || '',
            entry?.notes || '',
            createdAt ? new Date(createdAt as string)?.toLocaleDateString() : ''
          ]
        ];
      })
  ],
  ...styles
});

const noAlertDisclaimerText =
  'The information has been entered into HomeMeds, a medication assessment tool, and no medication alerts have been generated. Because no alerts were generated, this report has not been reviewed by a pharmacist. Appropriate medical judgment must be maintained when using this information.';

const showDisclaimerContent = report => {
  const arr: any = [];
  if (
    !report?.alerts?.criterionAlerts.length &&
    !report?.alerts?.medispanAlerts.length
  ) {
    arr.push([noAlertDisclaimerText]);
  } else {
    arr.push([report?.disclaimer]);
  }
  return arr;
};
// added disclaimer
const getDisclaimer = report => ({
  head: [],
  body: showDisclaimerContent(report),
  ...styles
});

export const assessmentReportPdf = {
  getTableTitle,
  getMemberDetails,
  getIncidents,
  getMedications,
  getConditions,
  getAllergies,
  getProviders,
  getPotentialProblems,
  getTherapies,
  getIngredients,
  getLatestAlertUpdate,
  getDisclaimer
};
