import { gql } from '@apollo/client';

export const GET_ALERTS = gql`
  query GetAlerts($data: GetAlertsInput!) {
    getAlerts(data: $data) {
      criterionAlerts {
        alertId
        createdAt
        status
        recommendations {
          id
          title
          description
          details
          disabled
        }
        history {
          historyId
          createdAt
          updatedBy
          lastAction
          notes
        }
        criterion {
          id
          title
          alias
          disabled
          description
        }
        assessmentId
      }
      medispanAlerts {
        medispanAlertId
        createdAt
        status
        assessmentId
        history {
          historyId
          createdAt
          updatedBy
          lastAction
          notes
        }
        drugs {
          name
          value
        }
        description
        messages {
          description
        }
      }
      patientDetails {
        assessmentCreatedAt
        patientName
        optionalPatientSiteId
      }
    }
  }
`;
