import { ROLES } from '../../constants';
import { getDateIgnoreTimezone } from '../../helpers';

export const preparePatientSubmit = (data, role) => {
  const newData = {
    ...data,
    date: getDateIgnoreTimezone(data?.dateOfBirth),
    active: data?.activeStatus === 'Active',
    siteId: localStorage.getItem('defaultSiteId')
  };
  delete newData.email;
  delete newData.phoneNumber;
  delete newData.phoneExtension;
  delete newData.userType;
  delete newData.activeStatus;
  delete newData.dateOfBirth;

  if (role === ROLES.ANALYST) delete newData.active;
  if (role === ROLES.GLOBAL_ADMIN && data?.assignSite)
    newData.siteId = data?.assignSite;

  delete newData.assignSite;
  if (data?.id) {
    delete newData.siteId;
    newData.patientId = data.id;
  }

  delete newData?.id;

  return newData;
};
