import { GridColDef } from '@material-ui/data-grid';

import { generateHexString } from '../helpers';
import { PATH_NAME } from '../../routes/pathNames';
import { RACE_OPTIONS, ETHNICITY_OPTIONS, MILITARY_OPTIONS } from '.';

export const REPORTING_TABS = [
  { id: 1, name: 'Patient Query', url: PATH_NAME.PATIENT_QUERY },
  { id: 2, name: 'Assessment Query', url: PATH_NAME.ASSESSMENT_QUERY },
  {
    id: 3,
    name: 'User Query',
    url: PATH_NAME.USER_QUERY
  },
  // { id: 3, name: 'Usage Query', url: PATH_NAME.USAGE_QUERY },
  { id: 4, name: 'Activity Summary', url: PATH_NAME.ACTIVITY_SUMMARY },
  { id: 5, name: 'Alerts Resolved', url: PATH_NAME.ALERTS_RESOLVED },
  { id: 6, name: 'Alerts Generated', url: PATH_NAME.ALERTS_GENERATED },
  { id: 7, name: '10 Meds or More', url: PATH_NAME.TEN_MEDS_OR_MORE },
  {
    id: 8,
    name: 'Duplicate Therapies & Drugs',
    url: PATH_NAME.DUPLICATE_DRUGS
  },
  // { id: 9, name: 'Reported Falls', url: PATH_NAME.REPORTED_FALLS },
  { id: 10, name: 'Age Distribution', url: PATH_NAME.AGE_DISTRIBUTION },
  { id: 11, name: 'Gender Distribution', url: PATH_NAME.GENDER_DISTRIBUTION },
  { id: 12, name: 'Incidents/Symptoms', url: PATH_NAME.INCIDENTS_SYMPTOMS },
  { id: 13, name: 'Conditions', url: PATH_NAME.CONDITIONS },
  { id: 14, name: 'Race', url: PATH_NAME.RACE },
  { id: 15, name: 'Origin', url: PATH_NAME.ETHNICITY },
  { id: 16, name: 'Military Status', url: PATH_NAME.MILITARY_STATUS }
];

export const EXPORT_OPTIONS = [
  { id: '1', name: 'PDF', handleItemClick: () => {} },
  { id: '2', name: 'PNG', handleItemClick: () => {} },
  { id: '3', name: 'CSV', handleItemClick: () => {}, component: undefined }
];

export const PLACEHOLDER_NAMES = [
  { id: 1, name: 'John Smith' },
  { id: 2, name: 'John Doe' }
];

export const GENDER = [
  { id: 1, name: 'Male', value: 'MALE' },
  { id: 2, name: 'Female', value: 'FEMALE' },
  { id: 3, name: 'Non-binary', value: 'NON_BINARY' },
  { id: 4, name: 'Prefer not to say', value: 'PREFER_NOT_TO_SAY' }
];

export const RACE = [
  { id: 1, name: 'Black' },
  { id: 2, name: 'Australian Aborigine and Papuan' },
  { id: 3, name: 'Bushmen/Hottentots' },
  { id: 4, name: 'Oriental/Amerindian' },
  { id: 5, name: 'White' }
];

export const INCLUDE_CHILD_SITES = [
  { id: 1, name: 'Yes', value: 'true' },
  { id: 2, name: 'No', value: 'false' }
];

export const PLACEHOLDER_QUANTITIES = [
  { id: 1, name: 'Minor' },
  { id: 2, name: 'Major' }
];

export const ACTIVITY_SUMMARY_DATA = [
  {
    name: 'Assessments with Contact MD',
    activitiesCount: 10,
    color: `#${generateHexString(6)}`
  },
  {
    name: 'Assessments with unresolved alerts',
    activitiesCount: 8,
    color: `#${generateHexString(6)}`
  },
  {
    name: 'Assessments with at least one resolution',
    activitiesCount: 9,
    color: `#${generateHexString(6)}`
  },
  {
    name: 'Assessments reviewed by Pharmacist',
    activitiesCount: 15,
    color: `#${generateHexString(6)}`
  },
  {
    name: 'Assessments with alerts',
    activitiesCount: 17,
    color: `#${generateHexString(6)}`
  },
  {
    name: 'Assessments with medication entered',
    activitiesCount: 29,
    color: `#${generateHexString(6)}`
  },
  {
    name: 'Medication assessments',
    activitiesCount: 34,
    color: `#${generateHexString(6)}`
  }
];

export const ACTIVITY_SUMMARY_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'patientName', headerName: 'Patient Name', flex: 1 },
  { field: 'age', headerName: 'Age', flex: 1 }
];

export const ACTIVITY_SUMMARY_ROWS = [
  {
    id: '1',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '2',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '3',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '4',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '5',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '6',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '7',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '8',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '9',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '10',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '11',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '12',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '13',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '14',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '15',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '16',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  },
  {
    id: '17',
    name: 'First, Last',
    age: '45',
    activityType: 'Assessments with unresolved alerts'
  }
];

export const EXPORTED_ACTIVITY_SUMMARY_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'activityName', headerName: 'Activity', flex: 4 },
  { field: 'count', headerName: 'Count', flex: 1 }
];

export const INCIDENTS_SYMPTOMS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'patientName', headerName: 'Patient Name', flex: 0.8 },
  { field: 'primaryStaffName', headerName: 'Staff Name', flex: 0.8 },
  {
    field: 'reportedFall',
    headerName: 'Falls',
    flex: 0.7,
    valueGetter: params => (params?.value ? 'Yes' : 'No')
  },
  {
    field: 'reportedDizziness',
    headerName: 'Dizzy',
    flex: 0.7,
    valueGetter: params => (params?.value ? 'Yes' : 'No')
  },
  {
    field: 'reportedConfusion',
    headerName: 'Confusion',
    flex: 0.8,
    valueGetter: params => (params?.value ? 'Yes' : 'No')
  },
  { field: 'drinksPerWeek', headerName: 'Days/Wk Drink', flex: 1 },
  { field: 'drinksPerDay', headerName: 'Drink/Occasion', flex: 1 },
  {
    field: 'hospitalizedEr',
    headerName: 'Hosp/ER',
    flex: 0.7,
    valueGetter: params => (params?.value ? 'Yes' : 'No')
  }
];

export const ALERTS_RESOLVED_DATA = [
  {
    name: 'Falls and Psychotropic Medication Use',
    foo: 5,
    color: `#${generateHexString(6)}`
  },
  {
    name: 'Confusion and Psychotropic Use',
    foo: 6,
    color: `#${generateHexString(6)}`
  },
  { name: 'Dizziness', foo: 9, color: `#${generateHexString(6)}` },
  {
    name: 'Cardiovascular (Blood Pressure)',
    foo: 15,
    color: `#${generateHexString(6)}`
  },
  { name: 'Opioid Use', foo: 25, color: `#${generateHexString(6)}` },
  { name: 'Duplicate Therapies', foo: 26, color: `#${generateHexString(6)}` },
  { name: 'Duplicate Ingredients', foo: 27, color: `#${generateHexString(6)}` }
];

export const ALERTS_RESOLVED_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'patientName', headerName: 'Patient Name', flex: 1 }
];

export const ALERTS_RESOLVED_ROWS = [
  { id: '1', name: 'First, Last', alertType: 'Alert A' },
  { id: '2', name: 'First, Last', alertType: 'Alert A' },
  { id: '3', name: 'First, Last', alertType: 'Alert A' },
  { id: '4', name: 'First, Last', alertType: 'Alert A' },
  { id: '5', name: 'First, Last', alertType: 'Alert A' },
  { id: '6', name: 'First, Last', alertType: 'Alert A' },
  { id: '7', name: 'First, Last', alertType: 'Alert A' },
  { id: '8', name: 'First, Last', alertType: 'Alert A' },
  { id: '9', name: 'First, Last', alertType: 'Alert A' },
  { id: '10', name: 'First, Last', alertType: 'Alert A' },
  { id: '11', name: 'First, Last', alertType: 'Alert A' },
  { id: '12', name: 'First, Last', alertType: 'Alert A' },
  { id: '13', name: 'First, Last', alertType: 'Alert A' },
  { id: '14', name: 'First, Last', alertType: 'Alert A' },
  { id: '15', name: 'First, Last', alertType: 'Alert A' },
  { id: '16', name: 'First, Last', alertType: 'Alert A' },
  { id: '17', name: 'First, Last', alertType: 'Alert A' }
];

export const EXPORTED_ALERTS_RESOLVED_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'alertType', headerName: 'Alert Type', flex: 3.55 },
  { field: 'numberOfPatients', headerName: 'Number of patients', flex: 1.3 },
  { field: 'percentage', headerName: 'Percentage', flex: 0.9 },
  { field: 'totalPatients', headerName: 'Total Patients', flex: 1.3 }
];

export const EXPORTED_ALERTS_RESOLVED_ROWS = [
  {
    id: 1,
    numberOfPatients: '41',
    alertType: 'Falls and Psychotropic Medication Use (12)',
    percentage: '12'
  },
  {
    id: 2,
    numberOfPatients: '45',
    alertType: 'NSAIDs and Age 80 or Older (3)',
    percentage: '3'
  },
  {
    id: 3,
    numberOfPatients: '46',
    alertType: 'Low Pulse & Bradycardic Agents  (0)',
    percentage: '0'
  },
  {
    id: 4,
    numberOfPatients: '47',
    alertType: 'Orthostasis (1)',
    percentage: '1'
  },
  {
    id: 5,
    numberOfPatients: '48',
    alertType: 'Confusion and Psychotropic Drug Use (12)',
    percentage: '12'
  },
  {
    id: 6,
    numberOfPatients: '54',
    alertType: 'NSAIDS & Anticoagulant Steroid Use (4)',
    percentage: '4'
  },
  {
    id: 7,
    numberOfPatients: '55',
    alertType: 'Low Systolic Blood Pressure with Related Meds (1)',
    percentage: '1'
  },
  {
    id: 7,
    numberOfPatients: '58',
    alertType: 'Fall & Antipsychotic Use  (1)',
    percentage: '1'
  },
  {
    id: 8,
    numberOfPatients: '60',
    alertType:
      'Elevated Blood Pressure/ sub-optimally controlled hypertension (16)',
    percentage: '16'
  },
  {
    id: 9,
    numberOfPatients: '1094',
    alertType: 'Opioid Use (15)',
    percentage: '15'
  },
  {
    id: 10,
    numberOfPatients: '1095',
    alertType: 'age tester (29)',
    percentage: '29'
  },
  {
    id: 11,
    numberOfPatients: '1096',
    alertType: 'Test (0)',
    percentage: '0'
  }
];

export const ALERTS_GENERATED_DATA = [
  { name: 'a', alertsCount: 5, color: `#${generateHexString(6)}` },
  { name: 'b', alertsCount: 6, color: `#${generateHexString(6)}` },
  { name: 'c', alertsCount: 9, color: `#${generateHexString(6)}` },
  { name: 'd', alertsCount: 15, color: `#${generateHexString(6)}` },
  { name: 'e', alertsCount: 25, color: `#${generateHexString(6)}` }
];

export const DUPLICATE_DRUGS_DATA = [
  { name: 'a', drugsCount: 10, color: `#${generateHexString(6)}` },
  { name: 'b', drugsCount: 8, color: `#${generateHexString(6)}` },
  { name: 'c', drugsCount: 9, color: `#${generateHexString(6)}` },
  { name: 'd', drugsCount: 15, color: `#${generateHexString(6)}` },
  { name: 'e', drugsCount: 17, color: `#${generateHexString(6)}` },
  { name: 'f', drugsCount: 29, color: `#${generateHexString(6)}` },
  { name: 'g', drugsCount: 34, color: `#${generateHexString(6)}` }
];

export const AGE_DISTRIBUTION_DATA = [
  { name: '20-29', patientsCount: 10, color: `#${generateHexString(6)}` },
  { name: '30-39', patientsCount: 8, color: `#${generateHexString(6)}` },
  { name: '40-49', patientsCount: 9, color: `#${generateHexString(6)}` },
  { name: '50-59', patientsCount: 15, color: `#${generateHexString(6)}` },
  { name: '60-69', patientsCount: 17, color: `#${generateHexString(6)}` },
  { name: '70-79', patientsCount: 29, color: `#${generateHexString(6)}` },
  { name: '80-89', patientsCount: 34, color: `#${generateHexString(6)}` },
  { name: '90+', patientsCount: 34, color: `#${generateHexString(6)}` }
];

export const TEN_MEDS_DATA = [
  { name: 'a', medsCount: 10, color: `#${generateHexString(6)}` },
  { name: 'b', medsCount: 8, color: `#${generateHexString(6)}` },
  { name: 'c', medsCount: 9, color: `#${generateHexString(6)}` },
  { name: 'd', medsCount: 15, color: `#${generateHexString(6)}` },
  { name: 'e', medsCount: 17, color: `#${generateHexString(6)}` },
  { name: 'f', medsCount: 29, color: `#${generateHexString(6)}` },
  { name: 'g', medsCount: 34, color: `#${generateHexString(6)}` }
];

export const TEN_MEDS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'patientName', headerName: 'Name', flex: 1 },
  { field: 'age', headerName: 'Age', flex: 1 },
  {
    field: 'fall',
    headerName: 'Fall',
    flex: 1,
    valueGetter: params => (params?.value ? 'Yes' : 'No')
  },
  {
    field: 'dizzy',
    headerName: 'Dizziness',
    flex: 1,
    valueGetter: params => (params?.value ? 'Yes' : 'No')
  },
  {
    field: 'hospitalized',
    headerName: 'Hospitalized',
    flex: 1,
    valueGetter: params => (params?.value ? 'Yes' : 'No')
  }
];

export const TEN_MEDS_ROWS = [
  { id: '1', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '2', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '3', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '4', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '5', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '6', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '7', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '8', name: 'First Last', age: '90', fallDizziness: 'No' },
  { id: '9', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '10', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '11', name: 'First Last', age: '90', fallDizziness: 'Yes' },
  { id: '12', name: 'First Last', age: '90', fallDizziness: 'Yes' }
];

export const DUPLICATE_DRUGS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'patientName', headerName: 'Patient Name', flex: 0.4 },
  { field: 'drugs', headerName: 'Drugs', flex: 1, sortable: false }
];

export const CONDITIONS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'patientName', headerName: 'Name', flex: 0.7 }
];

export const ALERTS_GENERATED_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'patientName', headerName: 'Name', flex: 0.7 },
  { field: 'alertType', headerName: 'Alert Type', flex: 1, sortable: false },
  { field: 'count', headerName: 'Count', flex: 0.5 },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.5
  },
  { field: 'totalPatients', headerName: 'Total Patients', flex: 0.5 }
];

export const CONDITIONS_ROWS = [
  {
    id: 1,
    name: 'Last First',
    diabetes: true,
    copd: true,
    heartFailure: true,
    hypertension: true,
    depression: true,
    arthritus: true
  },
  {
    id: 2,
    name: 'Last First',
    diabetes: false,
    copd: true,
    heartFailure: false,
    hypertension: false,
    depression: false,
    arthritus: true
  },
  {
    id: 3,
    name: 'Last First',
    diabetes: false,
    copd: false,
    heartFailure: false,
    hypertension: false,
    depression: false,
    arthritus: false
  },
  {
    id: 4,
    name: 'Last First',
    diabetes: true,
    copd: false,
    heartFailure: true,
    hypertension: true,
    depression: true,
    arthritus: false
  },
  {
    id: 5,
    name: 'Last First',
    diabetes: false,
    copd: true,
    heartFailure: true,
    hypertension: false,
    depression: false,
    arthritus: true
  },
  {
    id: 6,
    name: 'Last First',
    diabetes: true,
    copd: true,
    heartFailure: false,
    hypertension: true,
    depression: true,
    arthritus: true
  },
  {
    id: 7,
    name: 'Last First',
    diabetes: true,
    copd: false,
    heartFailure: true,
    hypertension: true,
    depression: true,
    arthritus: true
  },
  {
    id: 8,
    name: 'Last First',
    diabetes: false,
    copd: true,
    heartFailure: false,
    hypertension: false,
    depression: false,
    arthritus: false
  },
  {
    id: 9,
    name: 'Last First',
    diabetes: false,
    copd: true,
    heartFailure: false,
    hypertension: false,
    depression: false,
    arthritus: false
  },
  {
    id: 10,
    name: 'Last First',
    diabetes: false,
    copd: true,
    heartFailure: false,
    hypertension: false,
    depression: false,
    arthritus: true
  },
  {
    id: 11,
    name: 'Last First',
    diabetes: true,
    copd: true,
    heartFailure: false,
    hypertension: true,
    depression: false,
    arthritus: true
  },
  {
    id: 12,
    name: 'Last First',
    diabetes: false,
    copd: true,
    heartFailure: true,
    hypertension: false,
    depression: true,
    arthritus: true
  },
  {
    id: 13,
    name: 'Last First',
    diabetes: false,
    copd: false,
    heartFailure: false,
    hypertension: true,
    depression: true,
    arthritus: false
  },
  {
    id: 14,
    name: 'Last First',
    diabetes: false,
    copd: true,
    heartFailure: false,
    hypertension: true,
    depression: false,
    arthritus: true
  },
  {
    id: 15,
    name: 'Last First',
    diabetes: false,
    copd: true,
    heartFailure: true,
    hypertension: true,
    depression: false,
    arthritus: false
  },
  {
    id: 16,
    name: 'Last First',
    diabetes: true,
    copd: true,
    heartFailure: true,
    hypertension: true,
    depression: false,
    arthritus: true
  },
  {
    id: 17,
    name: 'Last First',
    diabetes: true,
    copd: false,
    heartFailure: true,
    hypertension: true,
    depression: false,
    arthritus: true
  }
];

export const CONDITIONS_DATA = [
  { name: 'Diabetes', conditionsCount: 42, color: `#${generateHexString(6)}` },
  { name: 'COPD', conditionsCount: 55, color: `#${generateHexString(6)}` },
  {
    name: 'Heart Failure',
    conditionsCount: 76,
    color: `#${generateHexString(6)}`
  },
  {
    name: 'Hypertension',
    conditionsCount: 64,
    color: `#${generateHexString(6)}`
  },
  {
    name: 'Depression/Anxiety',
    conditionsCount: 46,
    color: `#${generateHexString(6)}`
  },
  { name: 'Arthritis', conditionsCount: 64, color: `#${generateHexString(6)}` }
];

export const EXPORTED_CONDITIONS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'totalPatients', headerName: 'Total Patients', flex: 0.5 },
  { field: 'conditionName', headerName: 'Condition', flex: 1 },
  { field: 'count', headerName: 'Count', flex: 0.5 }
];

export const EXPORTED_CONDITIONS_ROWS = [
  { id: 1, totalPatients: 830, conditions: 'Diabetes', count: '301' },
  { id: 2, totalPatients: 830, conditions: 'COPD', count: '2' },
  { id: 3, totalPatients: 830, conditions: 'Heart Failure', count: '0' },
  { id: 4, totalPatients: 830, conditions: 'Hypertension', count: '400' },
  {
    id: 5,
    totalPatients: 830,
    conditions: 'Depression / Anxiety',
    count: '23'
  },
  { id: 6, totalPatients: 830, conditions: 'Athritis', count: '104' }
];

export const GENDER_DISTRIBUTION_LABELS = [
  { color: 'malesColor', value: 'males', title: 'Total Males' },
  { color: 'femalesColor', value: 'females', title: 'Total Females' },
  {
    color: 'nonBinariesColor',
    value: 'nonBinaries',
    title: 'Total Non-Binaries'
  },
  {
    color: 'notDisclosedColor',
    value: 'notDisclosed',
    title: 'Total Not Disclosed'
  }
];

export const GENDER_DISTRIBUTION_BARS = [
  { dataKey: 'malesCount', color: 'malesColor', title: 'Males', barSize: 70 },
  {
    dataKey: 'femalesCount',
    color: 'femalesColor',
    title: 'Females',
    barSize: 50
  },
  {
    dataKey: 'nonBinariesCount',
    color: 'nonBinariesColor',
    title: 'Non-Binaries',
    barSize: 35
  },
  {
    dataKey: 'notDisclosedCount',
    color: 'notDisclosedColor',
    title: 'Not Disclosed',
    barSize: 20
  }
];

export const GENDER_DISTRIBUTION_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'name', headerName: 'Patient Name', flex: 1 },
  { field: 'age', headerName: 'Age', flex: 1 },
  { field: 'siteName', headerName: 'Site Name', flex: 1 },
  { field: 'assignedStaff', headerName: 'Staff Assigned', flex: 1 }
];

export const EXPORTED_GENDER_DISTRIBUTION_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'age', headerName: 'Age Range', sortable: false, flex: 1 },
  { field: 'total', headerName: 'Total', sortable: false, flex: 1 },
  { field: 'males', headerName: 'Males', sortable: false, flex: 1 },
  { field: 'females', headerName: 'Females', sortable: false, flex: 1 },
  {
    field: 'nonBinaries',
    headerName: 'Non-Binaries',
    sortable: false,
    flex: 1
  },
  {
    field: 'notDisclosed',
    headerName: 'Not Disclosed',
    sortable: false,
    flex: 1
  }
];

export const USER_QUERY_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'firstName', headerName: 'First Name', flex: 1, sortable: true },
  { field: 'lastName', headerName: 'Last Name', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 1.4 },
  { field: 'role', headerName: 'Role', flex: 1 },
  { field: 'active', headerName: 'Status', flex: 1, cellClassName: 'status' },
  { field: 'siteTitle', headerName: 'Active Sites', flex: 1 }
];

export const PATIENT_QUERY_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'firstName', headerName: 'First Name', flex: 1 },
  { field: 'lastName', headerName: 'Last Name', flex: 1 },
  { field: 'patientId', headerName: 'Patient ID', flex: 1 },
  { field: 'optionalPatientSiteId', headerName: 'Patient Site ID', flex: 1 },
  { field: 'age', headerName: 'Age', flex: 0.7 },
  {
    field: 'gender',
    headerName: 'Gender',
    flex: 1,
    valueGetter: params =>
      GENDER?.find(gender => gender?.value === params?.value)?.name
  },
  {
    field: 'race',
    headerName: 'Race',
    flex: 1,
    valueGetter: params =>
      RACE_OPTIONS?.find(race => race?.id === params?.value)?.name
  },
  {
    field: 'ethnicity',
    headerName: 'Origin',
    flex: 1,
    valueGetter: params =>
      ETHNICITY_OPTIONS?.find(ethnicity => ethnicity?.id === params?.value)
        ?.name
  },
  {
    field: 'militaryStatus',
    headerName: 'Military Status',
    flex: 1,
    valueGetter: params =>
      MILITARY_OPTIONS?.find(
        militaryStatus => militaryStatus?.id === params?.value
      )?.name
  },
  { field: 'phone', headerName: 'Phone', flex: 1 },
  { field: 'city', headerName: 'City', flex: 1 },
  { field: 'state', headerName: 'State', flex: 1 },
  { field: 'zip', headerName: 'Zip', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 1 }
];

export const ASSESSMENT_QUERY_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'firstName', headerName: 'First Name', flex: 0.6 },
  { field: 'lastName', headerName: 'Last Name', flex: 0.6 },
  { field: 'assessmentId', headerName: 'Assessment ID', flex: 0.7 },
  { field: 'patientId_', headerName: 'Patient ID', flex: 1 },
  { field: 'optionalPatientSiteId', headerName: 'Patient Site ID', flex: 1 },
  { field: 'age_', headerName: 'Patient Age', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
  { field: 'siteTitle', headerName: 'Site Title', flex: 1 },
  {
    field: 'gender',
    headerName: 'Gender',
    flex: 1,
    valueGetter: params =>
      GENDER?.find(gender => gender?.value === params?.value)?.name
  },
  {
    field: 'race',
    headerName: 'Race',
    flex: 1,
    valueGetter: params =>
      RACE_OPTIONS?.find(race => race?.id === params?.value)?.name
  },
  {
    field: 'ethnicity',
    headerName: 'Origin',
    flex: 1,
    valueGetter: params =>
      ETHNICITY_OPTIONS?.find(ethnicity => ethnicity?.id === params?.value)
        ?.name
  },
  {
    field: 'militaryStatus',
    headerName: 'Military Status',
    flex: 1,
    valueGetter: params =>
      MILITARY_OPTIONS?.find(
        militaryStatus => militaryStatus?.id === params?.value
      )?.name
  },
  { field: 'phone', headerName: 'Phone', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'city', headerName: 'City', flex: 1 },
  { field: 'state', headerName: 'State', flex: 1 },
  { field: 'zip', headerName: 'Zip', flex: 1 },
  { field: 'staffName', headerName: 'Assigned Staff', flex: 0.7 },
  {
    field: 'createdAt',
    headerName: 'Creation Date',
    flex: 1,
    valueGetter: params =>
      new Date(params?.value as string)?.toLocaleDateString() || ''
  },
  {
    field: 'publishedAt',
    headerName: 'Publish Date',
    flex: 1,
    valueGetter: params =>
      !params?.value
        ? ''
        : new Date(params?.value as string)?.toLocaleDateString()
  },
  {
    field: 'completedAt',
    headerName: 'Completion Date',
    flex: 1,
    valueGetter: params =>
      !params?.value
        ? ''
        : new Date(params?.value as string)?.toLocaleDateString()
  }
];

export const ASSESSMENT_QUERY_ROWS = [
  {
    id: '1',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '2',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '3',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '4',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '5',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '6',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '7',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '8',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '9',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '10',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '11',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '12',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '13',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '14',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '15',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '16',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '17',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '18',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '19',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '20',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '21',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '22',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '23',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '24',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '25',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '26',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '27',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '28',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '29',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '30',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  },
  {
    id: '31',
    name: 'First Last',
    assessmentId: '12345678',
    patientId: '12345678',
    patientAge: '12345678',
    assignedStaff: '12345678',
    assessmentCreationDate: '12345678',
    assessmentPublication: '12345678'
  }
];

export const RACE_COLUMNS = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'name', headerName: 'Patient Name', flex: 1 },
  { field: 'age', headerName: 'Patient Age', flex: 0.4 },
  { field: 'site', headerName: 'Site', flex: 0.6 },
  {
    field: 'race',
    headerName: 'Race',
    flex: 1.4
  },
  { field: 'assignedStaff', headerName: 'Assigned Staff', flex: 1 }
];

export const MILITARY_STATUS_COLUMNS = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'name', headerName: 'Patient Name', flex: 1 },
  { field: 'age', headerName: 'Age', flex: 0.4 },
  { field: 'site', headerName: 'Site', flex: 0.6 },
  {
    field: 'militaryStatus',
    headerName: 'Military Status',
    flex: 1.4
  },
  { field: 'assignedStaff', headerName: 'Assigned Staff', flex: 1 }
];

export const EXPORTED_RACE_COLUMNS = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'race', headerName: 'Race', flex: 0.9 },
  { field: 'count', headerName: 'Number of patients', flex: 0.6 },
  { field: 'percentage', headerName: 'Percentage of the whole', flex: 0.7 },
  { field: 'site', headerName: 'Site ', flex: 0.8 }
];

export const EXPORTED_MILITARY_STATUS_COLUMNS = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'militaryStatus', headerName: 'Military Status', flex: 0.9 },
  { field: 'count', headerName: 'Number of patients', flex: 0.6 },
  { field: 'percentage', headerName: 'Percentage of the whole', flex: 0.7 },
  { field: 'site', headerName: 'Site ', flex: 0.8 }
];

export const ETHNICITY_COLUMNS = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'patientName', headerName: 'Patient Name', flex: 1 },
  { field: 'age', headerName: 'Age', flex: 0.6 },
  {
    field: 'ethnicity',
    headerName: 'Origin',
    flex: 1,
    valueGetter: params => params?.value ?? 'Not Completed'
  },
  { field: 'primaryStaffName', headerName: 'Assigned Staff', flex: 1.3 },
  { field: 'siteName', headerName: 'Site Name', flex: 1 }
];

export const AGE_DISTRIBUTION_COLUMNS = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'name', headerName: 'Name', flex: 0.5 },
  { field: 'age', headerName: 'Age', flex: 1 }
];

export const AGE_DISTRIBUTION_ROWS = [
  { id: 1, name: 'Last First', age: 20 },
  { id: 2, name: 'Last First', age: 21 },
  { id: 3, name: 'Last First', age: 23 },
  { id: 4, name: 'Last First', age: 51 },
  { id: 5, name: 'Last First', age: 43 },
  { id: 6, name: 'Last First', age: 21 },
  { id: 7, name: 'Last First', age: 17 },
  { id: 8, name: 'Last First', age: 99 },
  { id: 9, name: 'Last First', age: 76 }
];

export const EXPORTED_AGE_DISTRIBUTION_COLUMNS = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'age', headerName: 'Age', flex: 0.5 },
  { field: 'count', headerName: 'Count', flex: 1 }
];

export const EXPORTED_AGE_DISTRIBUTION_ROWS = [
  { id: 1, age: '20-29', count: 30 },
  { id: 2, age: '30-39', count: 7 },
  { id: 3, age: '40-49', count: 11 },
  { id: 4, age: '50-59', count: 65 },
  { id: 5, age: '60-69', count: 80 },
  { id: 6, age: '70-79', count: 115 },
  { id: 7, age: '80-89', count: 42 },
  { id: 8, age: '90+', count: 55 }
];
